import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";

const NotFoundPage = () => {
  return (
    <Layout>
      <HeadSEO title="404: Not Found" />
      <Box
        display="flex"
        maxWidth="container.md"
        margin="auto"
        minHeight="70vh"
        justifyContent="center"
        alignItems="center"
      >
        <VStack spacing="2rem">
          <Heading as="h1" size="2xl">
            404 Not Found
          </Heading>
          <Text>This content isn't available, or doesn't exist.</Text>
        </VStack>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;
